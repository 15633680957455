/* RW */
.rw {
  #nav {
    ul {
      background: #0066c2;
      a.is-active, a:hover, li:hover a.submenu, li:hover span.submenu, ul.submenu a.is-active, ul.submenu a:hover {
        background: #00529c;
      }
    }
    #menutoggle {
      background: #00529c;
      &:hover, &:active, &.is-active {
        background-color: lighten(#00529c, 7.5%);
      }
    }
  }
  h1, h2, h3, h4 {
    color: #0052A0;
    font-weight: 600;
  }
  ul.check > li:before {
    color: #0092C8;
  }
  #header-wrap {
    z-index: 10;
    background: #e6e6e6;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #fefefe), color-stop(100%, #e6e6e6));
    background-image: -webkit-linear-gradient(#fefefe, #e6e6e6);
    background-image: -moz-linear-gradient(#fefefe, #e6e6e6);
    background-image: -o-linear-gradient(#fefefe, #e6e6e6);
    background-image: linear-gradient(#fefefe, #e6e6e6);
  }
  #header {
    background: none;
    a.logo {
      margin-top: 15px;
      margin-bottom: 15px;
      max-width: 400px;
      img {
        max-width: 200px;
      }
    }
    .phone {
      position: absolute;
      right: 0;
      bottom: -36px;
      background: #00529C;
      border-radius: 0 0 3px 3px;
      padding: 5px 10px;
    }
  }
  #banner-wrap {
    #price-group-wrap {
      background: none;
        background: #00529C;
        color: #fff;
      .price-group {
        .price {
          color: #fff;
          margin-bottom: 0;
        }
        .pricing p {
          font-size: .9em;
          font-weight: 600;
        }
      }
    }
  }
  .aside.card {
    border: 1px solid #C5E2F0;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    -webkit-box-shadow: 0 0 5px rgba(10,10,10,0.1);
    -moz-box-shadow: 0 0 5px rgba(10,10,10,0.1);
    box-shadow: 0 0 5px rgba(10,10,10,0.1);
    h2, h3, h4 {
      border-bottom: 1px dotted #5faf43;
    }
  }
}
.price span.only {
  font-size: 16px;
  margin-bottom: 2px;
  margin-top: 0;
}

.hidden {
  display: none;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .rw {
    #header a.logo {
      img {
        display: block;
        float: left;
        max-width: 110px;
        height: auto;
        margin-right: 10px;
      }
      span.big {
        display: inline-block;
        font-size: 1.3em;
      }
      span.small {
        display: inline-block;
      }
    }
    #header a.logo img {
      max-width: 250px;
    }
  }
}

// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  .rw {
    #header-wrap { 
      border-top: 0;
      border-bottom: 1px solid #ccc;
    }
    #header a.logo {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

@media (min-width: 62em) {
  .rw #nav ul {
    margin: 0 0;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .rw {
    #nav {
      float: right;
      height: 40px;
      width: auto;
      background: none;
      clear: none;
      padding: 20px 0;
      li.register {
        background: #103d85;
        border-radius: 3px;
        &:hover, a:hover {
          background: lighten(#103d85, 8%)
        }
        a { 
          color: #fff;
        }
      }
      a {
        color: #555;
        text-shadow: 0 0 0;
        font-weight: 600;
        &:hover, &.is-active {
          color: #fff;
          background: #103d85;
        }
        &.livechat:before {
          display: inline-block;
          margin-right: 5px;
          width: inherit;
          height: inherit;
          content: "\e830";
          color: inherit;
          font-family: 'icons';
          font-weight: normal;
          text-align: center;
          font-size: 105%;
          vertical-align: top;
        }
      }
    }
  }
}

// Extra large devices (large desktops, 1080px and up)
@media (min-width: 1080px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .rw {
    #banner-wrap {
      #price-group-wrap {
        background: none;
      .price-group {
          position: absolute;
          bottom: 5px;
          right: 135px;
          &.bottom-20 { 
            bottom: 20px;
          }
          &.right-160 { 
            right: 160px;
          }
        }
      }
    }
  }
}